<template>
    <div class="app-container">
        <el-card class="box-card" id="contactType">
            <div slot="header" class="clearfix">
                <span style="font-weight: bold">{{$t('feedbackaff.title')}}</span>
            </div>
            <div>
                <el-input type="textarea" :rows="4" v-model="feedback">
                </el-input>
				<div class="separator"></div>
                <el-button class="b-c-sfb" type="primary" size="mini" icon="el-icon-check" plain @click.native="sendFeedback" style="width: 100%;">{{$t('feedbackaff.sendFeedback')}}</el-button>
            </div>
        </el-card>
    </div>
</template>

<script>
    import {
        sendFeedback
    } from '@/api/cont';

    import {
        debugPromise,
        calculateYearsBetween,
        get2D
    } from '@/utils/index';

    const Box = () =>
        import ('vue-info-box-element');

    export default {
        components: {
            Box
        },
        data() {
            return {
                feedback: ''
            };
        },
        methods: {
            async sendFeedback() {
                try {
                    const res = await sendFeedback({
                        feedback: this.feedback
                    });
                    console.log(res);
                } catch (e) {
                    console.log(e);
                }
            }
        },
        computed: {
        },
        watch: {
        },
        created() {
        }
    };
</script>